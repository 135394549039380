import { library, dom } from "@fortawesome/fontawesome-svg-core";
// icon package reference: https://fontawesome.com/how-to-use/with-the-api/setup/importing-icons

import { faAngleRight as falAngleRight } from "@fortawesome/pro-light-svg-icons/faAngleRight";
import { faArchive as falArchive } from "@fortawesome/pro-light-svg-icons/faArchive";
import { faExpandAlt as falExpandAlt } from "@fortawesome/pro-light-svg-icons/faExpandAlt";
import { faPlayCircle as falPlayCircle } from "@fortawesome/pro-light-svg-icons/faPlayCircle";
import { faPlay as falPlay } from "@fortawesome/pro-light-svg-icons/faPlay";
import { faInfoCircle as falInfoCircle } from "@fortawesome/pro-light-svg-icons/faInfoCircle";
import { faCheck as falCheck } from "@fortawesome/pro-light-svg-icons/faCheck";
import { faExclamationTriangle as falExclamationTriangle } from "@fortawesome/pro-light-svg-icons/faExclamationTriangle";
import { faExclamationCircle as falExclamationCircle } from "@fortawesome/pro-light-svg-icons/faExclamationCircle";
import { faBell as falBell } from "@fortawesome/pro-light-svg-icons/faBell";
import { faDownload as falDownload } from "@fortawesome/pro-light-svg-icons/faDownload";
import { faStopwatch as falStopwatch } from "@fortawesome/pro-light-svg-icons/faStopwatch";
import { faAngleLeft as falAngleLeft } from "@fortawesome/pro-light-svg-icons/faAngleLeft";
import { faTimesCircle as falTimesCircle } from "@fortawesome/pro-light-svg-icons/faTimesCircle";
import { faTimes as falTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { faChevronCircleRight as falChevronCircleRight } from "@fortawesome/pro-light-svg-icons/faChevronCircleRight";
import { faChevronCircleLeft as falChevronCircleLeft } from "@fortawesome/pro-light-svg-icons/faChevronCircleLeft";
import { faVideo as falVideo } from "@fortawesome/pro-light-svg-icons/faVideo";
import { faFilePdf as falFilePdf } from "@fortawesome/pro-light-svg-icons/faFilePdf";
import { faMicrophoneAlt as falMicrophoneAlt } from "@fortawesome/pro-light-svg-icons/faMicrophoneAlt";
import { faHashtag as falHashtag } from "@fortawesome/pro-light-svg-icons";
import { faSlash as falSlash } from "@fortawesome/pro-light-svg-icons";
import { faFilter as falFilter } from "@fortawesome/pro-light-svg-icons";
import { faPresentation as falPresentation } from "@fortawesome/pro-light-svg-icons";
import { faGlobe as falGlobe } from "@fortawesome/pro-light-svg-icons";
import { faMapMarker as falMapMarker } from "@fortawesome/pro-light-svg-icons";
import { faChalkboardTeacher as falChalkboardTeacher } from "@fortawesome/pro-light-svg-icons";
import { faClock as falClock } from "@fortawesome/pro-light-svg-icons";
import { faPhoneLaptop as falPhoneLaptop } from "@fortawesome/pro-light-svg-icons";
import { faUserGraduate as falUserGraduate } from "@fortawesome/pro-light-svg-icons";

import { faComment as fasComment } from "@fortawesome/free-solid-svg-icons/faComment";
import { faChevronRight as fasChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faChevronDown as fasChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faUserCircle as fasUserCircle } from "@fortawesome/free-solid-svg-icons/faUserCircle";

import { faInfoCircle as farInfoCircle } from "@fortawesome/pro-regular-svg-icons/faInfoCircle";
import { faEllipsisH as farEllipsisH } from "@fortawesome/pro-regular-svg-icons/faEllipsisH";
import { faSearch as farSearch } from "@fortawesome/pro-regular-svg-icons/faSearch";
import { faBrowser as farBrowser } from "@fortawesome/pro-regular-svg-icons/faBrowser";
import { faComment as farComment } from "@fortawesome/pro-regular-svg-icons/faComment";
import { faEnvelope as farEnvelope } from "@fortawesome/pro-regular-svg-icons/faEnvelope";
import { faBookmark as farBookmark } from "@fortawesome/pro-regular-svg-icons/faBookmark";

import { faFacebook as fabFacebook } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { faLinkedin as fabLinkedIn } from "@fortawesome/free-brands-svg-icons/faLinkedin";
import { faTwitter as fabTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faFacebookF as fabFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faLinkedinIn as fabLinkedinIn } from "@fortawesome/free-brands-svg-icons/faLinkedinIn";


library.add(
    falAngleRight,
    falArchive,
    falExpandAlt,
    falPlayCircle,
    falPlay,
    falInfoCircle,
    falCheck,
    falExclamationTriangle,
    falExclamationCircle,
    falChevronCircleRight,
    falChevronCircleLeft,
    falBell,
    falDownload,
    falStopwatch,
    falAngleLeft,
    falTimesCircle,
    falTimes,
    fasComment,
    fasChevronRight,
    fasChevronDown,
    farEnvelope,
    farInfoCircle,
    farEllipsisH,
    farSearch,
    fabFacebook,
    fabLinkedIn,
    fabTwitter,
    fabFacebookF,
    fabLinkedinIn,
    falVideo,
    falFilePdf,
    falMicrophoneAlt,
    farBrowser,
    falHashtag,
    falSlash,
    falFilter,
    falPresentation,
    falGlobe,
    falMapMarker,
    falChalkboardTeacher,
    falClock,
    falPhoneLaptop,
    farComment,
    falUserGraduate,
    fasUserCircle,
    farBookmark,
);

dom.watch();